import styled from "@emotion/styled";
import { ReactNode } from "react";

import { Displayer } from "@smart/itops-editor-dom";

import { GroupItem } from "../../types";
import { Group, GroupHeading } from "../group-components";

const SummaryGroupWrapper = styled(Group)`
  h5 {
    font-size: ${(props) => props.theme.fontSize.base};
    font-weight: 400;
    margin: ${(props) => props.theme.baseUnit * 0.6}rem 0;
    text-decoration: underline;
  }
`;

export type SummaryGroupProps = {
  group: GroupItem;
  index: number;
  children?: ReactNode;
};

export const SummaryGroup = ({ group, index, children }: SummaryGroupProps) => (
  <SummaryGroupWrapper>
    <GroupHeading group={{ ...group, hint: undefined }} index={index} />
    {group.description && <Displayer value={group.description} />}
    {children}
  </SummaryGroupWrapper>
);
