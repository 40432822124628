import { StaffDetails } from "@smart/bridge-types-basic/src/appointment";
import { UploadFileProps } from "@smart/itops-ui-dom";

import {
  AddressField,
  AppointmentField,
  CheckboxField,
  CurrencyField,
  DateField,
  EmailField,
  FieldComponentOverrideProps,
  FieldProps,
  FileField,
  InfoField,
  ListField,
  MultilineField,
  MultiListField,
  NumberField,
  PaymentField,
  PhoneNumberField,
  RadioField,
  TextField,
} from "./field-components";
import { LoadResponses, LookupOptions } from "../types";

const OPTION_THRESHOLD = 5;

export const IntakeField = ({
  addressLookup,
  fileLookup,
  uploadFileProps,
  staffDetails,
  fieldComponentOverride,
  groups,
  fields,
  visibility,
  submitResponses,
  ...props
}: FieldProps &
  LookupOptions &
  FieldComponentOverrideProps & {
    uploadFileProps: UploadFileProps;
    staffDetails: StaffDetails[];
    loadResponses: LoadResponses;
    fieldComponentOverride?: (
      fieldProps: FieldProps & FieldComponentOverrideProps,
    ) => React.ReactNode | undefined;
  }) => {
  const overOptionThreshold =
    (props.field.options?.length || 0) > OPTION_THRESHOLD;

  const componentOverride = fieldComponentOverride?.({
    ...props,
    groups,
    fields,
    visibility,
    submitResponses,
  });
  if (componentOverride) return componentOverride;

  switch (props.field.type) {
    case "text":
      return <TextField {...props} />;
    case "multilineText":
      return <MultilineField {...props} />;
    case "number":
      return <NumberField {...props} />;
    case "currency":
      return <CurrencyField {...props} />;
    case "choice":
      return overOptionThreshold ? (
        <ListField {...props} />
      ) : (
        <RadioField {...props} />
      );
    case "info":
      return <InfoField {...props} />;
    case "date":
      return <DateField {...props} />;
    case "checkbox":
      return overOptionThreshold ? (
        <MultiListField {...props} />
      ) : (
        <CheckboxField {...props} />
      );
    case "address":
      return <AddressField addressLookup={addressLookup} {...props} />;
    case "yesNo":
      return <RadioField {...props} />;
    case "phoneNumber":
      return <PhoneNumberField {...props} />;
    case "email":
      return <EmailField {...props} />;
    case "file":
      return (
        <FileField
          fileLookup={fileLookup}
          uploadFileProps={uploadFileProps}
          {...props}
        />
      );
    case "appointment":
      return <AppointmentField {...props} staffDetails={staffDetails} />;
    case "payment":
      return <PaymentField {...props} />;
    default:
      return <TextField {...props} />;
  }
};
