import styled from "@emotion/styled";
import { useState } from "react";

import { Modal, UploadFileProps } from "@smart/itops-ui-dom";
import { EditableInput } from "@smart/itops-ui-dom/src/components/editable-text/editable-input";

import {
  AutoFillStatus,
  FieldItem,
  LookupOptions,
  SectionItem,
  TeamItem,
} from "../../types";
import { IntakeAutoFill } from "../autofill";
import { FileField, FileFieldValue } from "../field-components";
import { Alert } from "../shared-components/alert";

const Description = styled.div<{ disabled?: boolean }>`
  margin-bottom: 1.5rem;
  color: ${(props) =>
    props.disabled ? props.theme.palette.disabled.base : "default"};
`;

export type IntakeFormAutoFillProps = {
  team: TeamItem;
  autoFillSection: SectionItem | undefined;
  sidebar: boolean;
  description?: string;
  disabled?: boolean;
  autoFillStatus?: AutoFillStatus;
  onSubmit?: (uploadedFileKeys: string[]) => Promise<void>;
  onSkip: () => void;
  onReset: () => void;
} & {
  fileLookup: LookupOptions["fileLookup"];
  uploadFileProps: UploadFileProps;
  isFileUploadDisabled?: boolean;
};

export const IntakeFormAutoFill = ({
  team,
  autoFillSection,
  description,
  sidebar,
  disabled,
  autoFillStatus,
  fileLookup,
  uploadFileProps,
  onSkip,
  onSubmit,
  onReset,
  isFileUploadDisabled,
}: IntakeFormAutoFillProps) => {
  const [uploadedFiles, setUploadedFiles] = useState<FileFieldValue[]>([]);
  const [confirming, setConfirming] = useState(false);

  if (!autoFillSection) return null;

  const autoFillField: FieldItem = {
    uri: "aiFillFieldUri",
    sectionUri: autoFillSection.uri,
    label: " ",
    description: "",
    order: "a",
    type: "file",
  };

  const isUploading = uploadedFiles.some((f) => f.uploadStatus === "uploading");
  const someUploaded = uploadedFiles.some((f) => f.uploadStatus === "uploaded");

  const onRunAutoFill = () => {
    if (!onSubmit) return;

    const uploadedFileKeys = uploadedFiles
      .filter((uf) => uf.uploadStatus === "uploaded" && uf.key)
      .map((uf) => uf.key!);

    if (uploadedFileKeys.length > 0) {
      onSubmit(uploadedFileKeys).catch((e) =>
        console.error("Something went wrong!", e),
      );
    }
  };

  const onSkipAutoFill = () => {
    setUploadedFiles([]);
    onSkip();
  };

  const shouldLock =
    autoFillStatus === "filling" || autoFillStatus === "completed";

  const supportedFileTypes = [
    { name: "jpeg", extension: ".jpeg" },
    { name: "jpeg", extension: ".jpg" },
    { name: "pdf", extension: ".pdf" },
    { name: "png", extension: ".png" },
    { name: "doc", extension: ".doc" },
    { name: "docx", extension: ".docx" },
  ];

  return (
    <IntakeAutoFill
      team={team}
      autoFillSection={autoFillSection}
      onSubmit={() => setConfirming(true)}
      onSkip={onSkipAutoFill}
      sidebar={sidebar}
      doneUploading={!isUploading && someUploaded}
      disabled={disabled}
      autoFillStatus={autoFillStatus}
    >
      {isFileUploadDisabled && (
        <Alert
          type="info"
          text="This feature is only available for your client. Please 'Skip' or select the next section."
        />
      )}
      <Description disabled={shouldLock}>
        <EditableInput text={description} fontSize="base" disabled />
      </Description>
      <FileField
        field={autoFillField}
        value={uploadedFiles}
        onChange={(files) => {
          onReset();
          setUploadedFiles(files);
        }}
        onBlur={() => {}}
        fileLookup={fileLookup}
        fileSizeLimitMegaByte={5}
        supportedFileTypes={supportedFileTypes}
        uploadFileProps={uploadFileProps}
        hideLabel
        disabled={disabled || shouldLock || isFileUploadDisabled}
      />
      <Modal
        open={!!confirming}
        onClose={() => setConfirming(false)}
        header={{ text: "Confirm" }}
        footer={{
          right: [
            {
              text: "Cancel",
              variant: "cancel",
              onClick: () => setConfirming(false),
            },
            {
              text: "Confirm",
              variant: "save",
              onClick: () => {
                setConfirming(false);
                onRunAutoFill();
              },
            },
          ],
        }}
      >
        <p>
          Before clicking &apos;Confirm&apos;, ensure all relevant documents are
          uploaded.
        </p>
        <p>
          Once confirmed, we will auto-populate the form, and no further uploads
          or use of the &apos;Autofill Assistant&apos; will be possible.
        </p>
      </Modal>
    </IntakeAutoFill>
  );
};
