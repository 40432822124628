import { validateConditionLink } from "@smart/bridge-types-basic";
import {
  fromEntries,
  isNotNullOrUndefined,
  keysOf,
} from "@smart/itops-utils-basic";

import { groupLength } from "./render-ordered-items";
import { FieldItem, GroupItem, LinkItem, SectionItem } from "../types";

export type VisibilityOptions = {
  sections: SectionItem[];
  groups: GroupItem[];
  fields: FieldItem[];
  responses: Record<string, any>;
};

export type Visibility = {
  visibleFields: Record<string, boolean>;
  visibleGroups: Record<string, boolean>;
  visibleSections: Record<string, boolean>;
  visibleRepeatableFields: Record<string, boolean[]>;
  willBeHidden: (
    update: Record<string, any>,
    group?: GroupItem,
    index?: number,
  ) => string[];
};

const visibilityCheck = ({
  fields,
  links,
  responses,
  groups,
}: {
  fields: FieldItem[];
  links: LinkItem[] | undefined | null;
  responses: Record<string, any>;
  groups: GroupItem[];
}) =>
  !links?.length ||
  links.every((link) => {
    const field = fields.find((f) => f.uri === link.fieldUri);
    return (
      !field ||
      validateConditionLink({
        conditionLink: link,
        fieldValue: responses[link.fieldUri],
        fieldType: field.type,
        group: groups?.find((g) => field.groupUri && g.uri === field.groupUri),
      })
    );
  });

// Check visibility of fields within the same repeatable group
const visibilityCheckRepeatableGroup = ({
  fields,
  links,
  responses,
  groups,
  groupUri,
  index,
}: {
  fields: FieldItem[];
  links: LinkItem[] | undefined | null;
  responses: Record<string, any>;
  groups: GroupItem[];
  groupUri?: string | null;
  index?: number;
}) => {
  const group = groups.find((g) => g.uri === groupUri);
  if (!group?.repeatable) return true;

  // Only check links to fields within the same group only,
  // links to fields outside of the group are checked in the visibilityCheck function.
  const inGrouplinks = links?.filter(
    (l) => fields.find((f) => f.uri === l.fieldUri)?.groupUri === groupUri,
  );

  return (
    !inGrouplinks?.length ||
    inGrouplinks.every((link) => {
      const field = fields.find((f) => f.uri === link.fieldUri);

      return (
        !field ||
        validateConditionLink({
          conditionLink: link,
          fieldValue: responses[link.fieldUri],
          fieldType: field.type,
          group: groups?.find(
            (g) => field?.groupUri && g.uri === field.groupUri,
          ),
          affectingGroupUri: groupUri,
          index,
        })
      );
    })
  );
};

const createVisibilityMap = ({
  sections,
  groups,
  fields,
  responses,
}: VisibilityOptions) => {
  const visibleSections = fromEntries(
    sections.map(({ uri, links }) => [
      uri,
      visibilityCheck({ fields, responses, links, groups }),
    ]),
  );
  const visibleGroups = fromEntries(
    groups.map(({ uri, sectionUri, links }) => [
      uri,
      visibleSections[sectionUri]
        ? visibilityCheck({ fields, responses, links, groups })
        : false,
    ]),
  );
  const isRepeatableField = (field: FieldItem) =>
    !!groups.find((g) => g.uri === field.groupUri)?.repeatable;
  // Map for non-repeatable fields visibility
  const visibleFields = fromEntries(
    fields.map((f) => {
      const linksToCheck = f.links?.filter(
        (l) =>
          !isRepeatableField(f) ||
          fields.find((lf) => lf.uri === l.fieldUri)?.groupUri !== f.groupUri,
      );
      return [
        f.uri,
        visibleSections[f.sectionUri] &&
        (!f.groupUri || visibleGroups[f.groupUri])
          ? visibilityCheck({ fields, responses, links: linksToCheck, groups })
          : false,
      ];
    }),
  );

  // Visible repeatable fields map
  const visibleRepeatableFields = fromEntries(
    fields
      .filter((f) => isRepeatableField(f) && visibleFields[f.uri])
      .map((rf) => {
        const group = groups.find((g) => g.uri === rf.groupUri);
        const length = groupLength({
          group: group!,
          fields: fields.filter((f) => f.groupUri === group?.uri),
          responses,
        });
        return [
          rf.uri,
          Array.from({ length }).map((_, index) =>
            visibilityCheckRepeatableGroup({
              fields,
              links: rf.links,
              responses,
              groups,
              groupUri: rf.groupUri,
              index,
            }),
          ),
        ];
      }),
  );
  return {
    visibleSections,
    visibleGroups,
    visibleFields,
    visibleRepeatableFields,
  };
};

const checkForHidden = (
  options: VisibilityOptions,
  existing: Record<string, boolean>,
  responses: Record<string, any>,
  index?: number,
) => {
  const updated = createVisibilityMap({
    ...options,
    responses,
  });

  const isRepeatableFieldHidden = (uri: string) =>
    index !== undefined &&
    updated.visibleRepeatableFields[uri]?.[index] === false;

  return {
    updatedFields: updated.visibleFields,
    willBeHiddenUris: keysOf(updated.visibleFields).filter(
      (u) =>
        (!updated.visibleFields[u] || isRepeatableFieldHidden(u)) &&
        !!existing[u],
    ),
  };
};

export const useVisibility = (options: VisibilityOptions): Visibility => {
  const {
    visibleSections,
    visibleGroups,
    visibleFields,
    visibleRepeatableFields,
  } = createVisibilityMap(options);

  return {
    visibleSections,
    visibleGroups,
    visibleFields,
    visibleRepeatableFields,
    willBeHidden: (
      updatedResponses: Record<string, any>,
      group?: GroupItem,
      index?: number,
    ) => {
      const uris: string[] = [];
      const responses = { ...options.responses, ...updatedResponses };

      let shouldCheck = true;
      let existing = visibleFields;
      let iteration = 0;
      while (shouldCheck && iteration <= 5) {
        const { updatedFields, willBeHiddenUris } = checkForHidden(
          options,
          existing,
          responses,
          index,
        );
        existing = updatedFields;
        shouldCheck = !!willBeHiddenUris.length;
        for (const uri of willBeHiddenUris) {
          const field = options.fields.find((f) => f.uri === uri);
          if (field?.groupUri && group) {
            // Check if the group field belongs to the same group
            if (
              group.uri === field.groupUri &&
              index !== undefined &&
              Array.isArray(responses[uri]) &&
              isNotNullOrUndefined(responses[uri][index])
            ) {
              responses[uri][index] = null;
            }
          } else {
            responses[uri] = null;
          }
          uris.push(uri);
        }

        iteration += 1;
      }

      return uris;
    },
  };
};
