import { FieldErrors } from "react-hook-form";

import { isNotNullOrUndefined } from "@smart/itops-utils-basic";

import { fieldName } from "./field";
import {
  FieldError,
  FieldItem,
  GroupError,
  OrderedGroup,
  OrderedItem,
  SectionErrors,
} from "../types";

export const fieldError = (
  field: FieldItem,
  error: FieldErrors[string],
  index?: number,
): FieldError | undefined =>
  error
    ? {
        name: fieldName({ field, index }),
        label: field.label,
        error: error?.message?.toString() || "Please enter a valid response",
      }
    : undefined;

export const groupError = (
  { group, visibleFields }: OrderedGroup,
  errors: FieldErrors,
  index: number,
): GroupError | undefined => {
  const fieldErrors = visibleFields
    .map((field) =>
      fieldError(field, (errors?.[field.uri] as FieldErrors)?.[index], index),
    )
    .filter(isNotNullOrUndefined);

  return fieldErrors.length
    ? {
        name: fieldName({ field: group, index }),
        fieldErrors,
        index: group.repeatable ? index : undefined,
        label: group.label,
      }
    : undefined;
};

export const useOrderedErrors = (
  { orderedItems }: { orderedItems: OrderedItem[] },
  state: {
    isValid: boolean;
    errors: FieldErrors;
  },
): SectionErrors | undefined => {
  if (state.isValid) return undefined;

  const errors = orderedItems
    .map((item) =>
      item.type === "field"
        ? fieldError(item.field, state.errors[item.field.uri])
        : groupError(item, state.errors, item.index || 0),
    )
    .filter(isNotNullOrUndefined)
    .flat();

  const total = errors.reduce(
    (agg, e) => agg + (e.fieldErrors?.length || 1),
    0,
  );

  return { errors, total };
};
