import styled from "@emotion/styled";
import { MutableRefObject } from "react";
import { RefCallBack } from "react-hook-form";

import { Visibility } from "../../hooks";
import { FieldItem, GroupItem, ResponseSubmitter } from "../../types";

export const FieldsList = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: ${(props) => props.theme.baseUnit * 2}rem;
`;

export const FieldWrapper = styled.div<{ isLoading?: boolean }>`
  ${(props) => props.isLoading && "opacity: 0.5;"}
  position: relative;

  &[aria-disabled="true"] {
    pointer-events: none;
  }

  h4,
  h5 {
    font-size: ${(props) => props.theme.fontSize.emphasis};
    font-weight: 400;
    margin: ${(props) => props.theme.baseUnit * 0.6}rem 0;
  }

  p {
    font-size: ${(props) => props.theme.fontSize.base};
    font-weight: 400;
    margin: ${(props) => props.theme.baseUnit * 0.6}rem 0;
  }
`;

export type FieldProps<E = any, V = any> = {
  field: FieldItem;
  index?: number;
  value: V;
  onChange: (value: V) => void;
  onBlur: () => void;
  error?: string;
  innerRef?: RefCallBack | MutableRefObject<E>;
  disabled?: boolean;
  loading?: boolean;
  hideLabel?: boolean;
  isPreview?: boolean;
};

export type FieldComponentOverrideProps = {
  groups: GroupItem[];
  fields: FieldItem[];
  visibility: Visibility;
  submitResponses: ResponseSubmitter;
  responses: Record<string, any>;
};
