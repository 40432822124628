import { ReactNode } from "react";

import { FieldItem, GroupItem, OrderedItem } from "../types";

export const groupLength = ({
  group,
  fields,
  responses,
}: {
  group: GroupItem;
  fields: FieldItem[];
  responses: Record<string, any>;
}) => {
  if (!group.repeatable) return 1;

  return Math.max(
    ...fields.map((f) =>
      Array.isArray(responses[f.uri]) ? responses[f.uri].length : 1,
    ),
  );
};

export type RenderOrderedItemsOptions = {
  orderedItems: OrderedItem[];
  responses: Record<string, any>;
  renderGroup: (props: {
    key: string;
    group: GroupItem;
    fields: FieldItem[];
    length: number;
    index: number;
    children: ReactNode;
  }) => JSX.Element;
  renderField: (props: {
    key: string;
    index?: number;
    field: FieldItem;
    value: any;
    group?: GroupItem;
  }) => JSX.Element | null;
};

export const renderOrderedItems = ({
  orderedItems,
  responses,
  renderGroup,
  renderField,
}: RenderOrderedItemsOptions) =>
  orderedItems.flatMap((item) => {
    if (item.type === "field")
      return renderField({
        key: item.field.uri,
        field: item.field,
        value: responses[item.field.uri],
      });

    const length = groupLength({ ...item, responses });
    const groupIndex = item.index || 0;

    return renderGroup({
      index: groupIndex || 0,
      length,
      // The key contains the length to trigger re-renders when deleting a repeatable group item
      key: `${item.group.uri}-${groupIndex}-${length}`,
      group: item.group,
      fields: item.fields,
      children: item.visibleFields.map((field) => {
        const value = Array.isArray(responses[field.uri])
          ? responses[field.uri][groupIndex]
          : responses[field.uri];

        return renderField({
          key: item.group.repeatable ? `${field.uri}-${groupIndex}` : field.uri,
          index: groupIndex,
          field,
          group: item.group,
          value,
        });
      }),
    });
  });
