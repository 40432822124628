import {
  useFlags,
  useLDClient,
  AllFlagsLDClient,
} from "launchdarkly-react-client-sdk";
import { useState } from "react";

import { loadEnv } from "@smart/bridge-env-dom";
import { useAsync } from "@smart/itops-hooks-dom";
import { getBuildVersion, isTestEnv, v4 } from "@smart/itops-utils-basic";

import {
  FeatureFlags,
  SmokeballFeatureFlags,
  SmokeballFeatureFlagsValue,
} from "./types";
import { skipFeatureFlags } from "./utils";

type LDContext = Parameters<AllFlagsLDClient["ldClient"]["identify"]>[0];

const defaultLocalFeatureFlags: FeatureFlags = {
  actAsFirm: true,
  formImportExport: true,
  globalForm: true,
  tabBannerInfo: undefined,
  builderBannerInfo: undefined,
  familyPro: true,
  leadPro: true,
  matterFlow: true,
  formBuilderV2Closer: true,
  sendToClientOnly: true,
  aiUserFeedback: true,
  billingField: true,
  matterSpecificForms: true,
  clientPopulateIntakeWithFile: true,
  wordGenerationIntake: true,
  staffPopulateIntakeWithFile: true,
  archieFeature: "enabled",
  "LINK-2488": true,
  "LOOP-1134": true,
};

export const defaultLocalSmokeballFeatureFlags: SmokeballFeatureFlags = {
  matterTypes: ".*",
  fusionFamilyPropertyIntegrationV2: {
    QuestionnaireMatterTypeIds: [],
    FamilyPropertyMatterTypeIds: [],
    IntakeMatterTypeIds: [],
  },
};

let forcedFlags: Partial<FeatureFlags> | undefined;

export const forceFeatureFlags = (flags: Partial<FeatureFlags> | undefined) => {
  if (!isTestEnv()) throw new Error("forceFeatureFlags only available in test");

  forcedFlags = flags;
};

let forcedSBFlags: Partial<SmokeballFeatureFlags> | undefined;

export const forceSBFeatureFlags = (
  flags: Partial<SmokeballFeatureFlags> | undefined,
) => {
  if (!isTestEnv())
    throw new Error("forceSBFeatureFlags only available in test");

  forcedSBFlags = flags;
};

export const useFeatureFlags = () => {
  if (skipFeatureFlags())
    return { ...defaultLocalFeatureFlags, ...forcedFlags };
  return useFlags<FeatureFlags>();
};

export const useSmokeballFeatureFlags = () => {
  if (skipFeatureFlags()) {
    const flags = {
      ...defaultLocalSmokeballFeatureFlags,
      ...forcedSBFlags,
    };

    return {
      matterTypes: flags.matterTypes,
      fusionFamilyPropertyIntegrationV2: JSON.stringify(
        flags.fusionFamilyPropertyIntegrationV2,
      ),
    };
  }

  return useFlags<SmokeballFeatureFlagsValue>();
};

export type IdentifyProps = {
  team?: { uri?: string; name?: string; provider?: string };
  user?: { uri?: string; email?: string };
};

export const useIdentify = (): {
  identifyFeatureFlagUser: (props: IdentifyProps) => void;
  identified: boolean;
} => {
  if (skipFeatureFlags())
    return { identifyFeatureFlagUser: async () => {}, identified: true };

  const [identity, identifyFeatureFlagUser] = useState<IdentifyProps>();
  const [identified, setIdentified] = useState(false);
  const client = useLDClient();

  useAsync(async () => {
    if (client && identity) {
      const { user, team } = identity;
      const { version, build } = getBuildVersion();
      const env = loadEnv("Env");
      const region = loadEnv("Region");

      const context: LDContext = {
        kind: "multi",
        user: {
          key: user?.uri || `anonymous-${v4()}`,
          teamUri: team?.uri,
          teamName: team?.name,
          appVersion: version,
          hostname: window.location.hostname,
        },
        environment: {
          key: `${env}-${region}`,
          name: `${env} ${region}`,
          env,
          region,
        },
        version: {
          key: build,
          name: build,
          version,
          build,
        },
      };
      if (team && team.uri)
        context.team = {
          key: team.uri,
          name: team.name,
          product: team.provider,
        };

      await client.identify(context);
      setIdentified(true);
    }
  }, [client, identity]);

  return {
    identified,
    identifyFeatureFlagUser,
  };
};
